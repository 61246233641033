<template>
  <!-- 医院端 订单管理 -- 我的课程 -- 学员列表 /web/studentList -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的课程</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div
              title="姓名"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="dataForm.userName"
                type="text"
                size="small"
                placeholder="请输入姓名"
                clearable
              />
            </div>
            <div
              title="身份证号"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">身份证号: </span>
              <el-input
                v-model="dataForm.idcard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>

            <div
              title="手机号"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel" style="min-width:7rem"> 手机号: </span>
              <el-input
                v-model="dataForm.mobile"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="认证状态" class="searchboxItem ci-full">
              <span class="itemLabel">认证状态:</span>
              <el-select
                clearable
                size="small"
                v-model="dataForm.authentication"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in authenticationType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学习状态:</span>
              <el-select
                clearable
                size="small"
                v-model="dataForm.completeState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in studyStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="有无证件照片" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >有无证件照片:</span
              >
              <el-select
                size="small"
                v-model="dataForm.hasIdPhoto"
                filterable
                clearable
                placeholder="请选择有无证件照片"
              >
                <el-option
                  v-for="item in hasIdPhotoSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="入班时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 90px">入班时间:</span>
              <el-date-picker
                v-model="dataForm.theClassTime"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getListData()">
                查询
              </el-button>
              <!-- <el-button class="bgc-bv" round @click="handleCreate()">
                新增
              </el-button> -->
              <el-button class="bgc-bv" round @click="handleExport()">
                批量导入
              </el-button>
            </div>
          </div>
        </div>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @sort-change="changeSort"
              :default-sort="{ prop: 'theClassTime', order: 'ascending' }"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="80px"
                :index="indexMethod1"
                fixed
              />

              <template v-if="tableHaed.length">
                <el-table-column
                  v-for="(el, index) in tableHaed"
                  :key="index"
                  :label="el.name"
                  :prop="el.key"
                  :minWidth="130"
                  :fixed="el.name == '姓名' || el.name == '身份证号'|| el.name == '手机号'? true : false"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span>{{ getValue(el, scope.row) }}</span>
                  </template>
                </el-table-column>
              </template>
              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleViewStudentInfo(scope.row)"
                    >查看
                  </el-button>
                  <!--  -->
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.studyState != 10"
                    @click="handleDel(scope.row)"
                    >从本班移除
                  </el-button>

                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleLearningRecord(scope.row)"
                    >学习记录
                  </el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <div class="pageNum">
          <el-pagination
            :total="fenYe.total"
            :page-size="fenYe.size"
            :current-page="fenYe.currentPage"
            background
            :page-sizes="[10, 20, 30, 40, 50]"
            layout="total, sizes,prev, pager, next,jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>

        <!-- 查看学员信息 -->
        <el-dialog
          title="查看学员信息"
          :visible.sync="dialogViewStudentInfo"
          width="55%"
          top="1%"
          :center="true"
          @close="doCloseViewStudentInfo"
          :close-on-click-modal="false"
        >
          <div class="ovy-a" style="height: 500px">
            <el-form label-width="130px" ref="Form" :model="Form">
              <el-form-item
                :label="item.name"
                prop="userName"
                v-for="(item, index) in showKey"
                :key="index"
              >
                <el-input
                  v-if="
                    item.key != 'sex' &&
                      item.key != 'workUnit' &&
                      item.key != 'idPhotoShow'
                  "
                  v-model="Form[item.key]"
                  type="text"
                  size="small"
                  :placeholder="'请输入'+ item.name"
                  :disabled="adaptable[item.key]? false :true"
                />
                <el-radio-group v-if="item.key == 'sex'" v-model="Form.sex" :disabled="true">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
                <el-select
                  v-if="item.key == 'workUnit'"
                  v-model.trim="Form.compId"
                  filterable
                  clearable
                  placeholder="请输入工作单位"
                  size="small"
                  :remote-method="getCompanyList"
                  remote
                >
                  <el-option
                    v-for="item in companyList"
                    :key="(item || {}).compId"
                    :label="(item || {}).compName"
                    :value="(item || {}).compId"
                  ></el-option>
                </el-select>
                <div class="flexdc" v-if="item.key == 'idPhotoShow'">
                  <div class="df idPhoto" style="margin-bottom: 10px">
                    <div
                      @mouseleave="showDel = false"
                      @mouseover="showDel = true"
                    >
                      <div v-show="Form.idPhotoKey && showDel" class="flexdcc">
                        <i
                          class="el-icon-delete"
                          style="color: #fff; font-size: 20px"
                          @click="delIdPhoto"
                        ></i>
                      </div>
                      <img
                        style="width: 135px; height: 189px"
                        :src="
                          Form.idPhotoShow ||
                            require('@/assets/id_photo_example.png')
                        "
                        alt
                      />
                    </div>
                  </div>
                  <div class="pic_upload">
                    <el-upload
                      :on-change="handleAvatarSuccessIdPhoto"
                      :before-upload="beforeIdPhotoUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-button
                        size="small"
                        class="bgc-bv"
                        style="margin-left: 30px"
                        >点击上传</el-button
                      >
                    </el-upload>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <span
            slot="footer"
            class="dialog-footer"
            style="display: flex; justify-content: center"
          >
            <el-button class="bgc-bv" @click="doCloseViewStudentInfo()"
              >取消</el-button
            >
            <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
          </span>
        </el-dialog>

        <!-- 导入学员 -->
        <el-dialog
          :title="'学员批量导入'"
          :visible.sync="centerDialogVisible"
          width="50%"
          center
          @close="doCloseload"
          class="exportdialog"
        >
          <div class="flexdcc">
            <div class="export-box">
              <h1>
                1
                <span>下载并填写导入模板</span>
              </h1>
              <div class="df">
                <span
                  v-for="(item, index) in templatelist"
                  :key="index"
                  @click="downloadExcel(item.templatePath)"
                  style="
                    margin-top: 20px;
                    margin-right: 20px;
                    color: #409eff;
                    cursor: pointer;
                  "
                  >{{ item.templateName }}</span
                >
              </div>
            </div>
            <div class="export-box">
              <h1>
                2
                <span>导入编写好的Excel文档</span>
              </h1>

              <el-form
                :inline="true"
                ref="DialogForm"
                :model="DialogForm"
                :rules="Dialogrules"
                style="align-items: center; display: flex; margin-top: 20px"
              >
              </el-form>
              <div class="flexdc">
                <div class="df" style="margin: 0 0 10px">
                  <el-upload
                    class="upload-demo upload-workers"
                    :action="actionUrl"
                    :on-error="handleError"
                    :on-success="handleSuccess"
                    :on-change="uploadChange"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button
                      class="bgc-bv"
                      style="font-size: 12px; width: 80%"
                      >浏览</el-button
                    >
                  </el-upload>
                  <p
                    v-if="fileName"
                    style="margin-top: 10px; margin-left: 10px"
                  >
                    当前选择文件：
                    <span style="color: #f46173">{{ fileName }}</span>
                  </p>
                  <p v-else style="margin-top: 10px; margin-left: 10px">
                    未选择文件
                  </p>
                </div>
                <div>
                  <el-button
                    class="bgc-bv"
                    size="mini"
                    style="margin-top: 10px; height: 35px"
                    @click="doExport('DialogForm')"
                    >开始导入</el-button
                  >
                </div>
                <div style="margin-top: 10px" v-if="progressVal > 0">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="20"
                    :percentage="progressVal"
                  ></el-progress>
                </div>
              </div>
            </div>
            <div class="export-box">
              <h1>
                3
                <span>导入结果</span>
              </h1>
              <div class="df studentNum" style="margin: 1rem 0">
                <span>学员总数量:{{ totalNum }}人;</span>
                <span>成功:{{ correctNum }}人;</span>
                <span>失败:{{ errorNum }}人;</span>
              </div>
              <div>
                <el-button
                  class="bgc-bv"
                  style="margin-top: 15px; height: 35px"
                  size="mini"
                  :disabled="errorNum == '0'"
                  @click="doExportError"
                  >导出错误数据</el-button
                >
              </div>
              <div
                style="margin-top: 15px; color: #dd1d35"
                v-if="errorNum != 0"
              >
                有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
              </div>
            </div>
          </div>
        </el-dialog>
        <!-- vueCropper 剪裁图片实现-->
        <el-dialog
          title="图片剪裁"
          :visible="dialogVisibleCropper"
          top="2%"
          :modal="false"
          :before-close="docropperCencal"
          class="vueCropper_dialog"
        >
          <div class="cropper-content">
            <div class="cropper" style="text-align: center">
              <vueCropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="true"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :centerBox="option.centerBox"
                :infoTrue="option.infoTrue"
                :fixedBox="option.fixedBox"
                :mode="option.mode"
              ></vueCropper>
            </div>
          </div>
          <p class="flexcb" style="margin: 5px 0 15px">
            <span style="color: #e90000"
              >如果您上传的图片像素过高，可以将鼠标放到图片上滑动鼠标滚轮进行缩放。</span
            >
            <el-upload
              :on-change="handleAvatarSuccessIdPhoto"
              :before-upload="beforeIdPhotoUpload"
              :http-request="$requestMine"
              :show-file-list="false"
              class="img-el-upload"
              style="display: inline"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <span
                style="
                  color: #5c6be8;
                  cursor: pointer;
                  text-decoration: underline;
                "
                >重新上传</span
              >
            </el-upload>
          </p>
          <div class="cropper_tip">
            <h3>上传照片要求</h3>
            <div class="flexcb">
              <div class="flexac">
                <span class="tip_index">1</span>
                <span class="tip_text">人像清晰，神态自然无明显变形</span>
              </div>
              <div class="flexac">
                <span class="tip_index">2</span>
                <span class="tip_text">人像占照片尺寸2/3 纯色背景</span>
              </div>
              <div class="flexac">
                <span class="tip_index">3</span>
                <span class="tip_text">35*49mm或413*578像素 二寸照片最佳</span>
              </div>
            </div>
          </div>
          <div slot="footer" class="flexcc">
            <el-button @click="docropperCencal">取 消</el-button>
            <el-button class="bgc-bv" @click="finish">确认</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopagent";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/accountList",
  components: {
    Empty,
  },
  mixins: [List],
  data() {
    return {
      courseId: this.$route.query.courseId, // 课程id
      courseName: this.$route.query.courseName, // 课程名称
      projectId: this.$route.query.projectId, // 班级id
      projectCourseId: this.$route.query.projectCourseId, // 班级课程id
      remainCount: this.$route.query.remainCount, // 剩余数量
      // 列表筛选条件
      dataForm: {
        userName: "", // 姓名
        idcard: "", // 身份证号
        mobile: "", // 手机号
        authentication: "", // 认证状态
        completeState: "", // 学习状态
        hasIdPhoto: "", //有无证件照片
        theClassTime: "", // 入班时间
      },
      authenticationType: [], // 认证状态列表
      studyStatusList: [], // 学习状态列表
      companyList: [], // 公司列表
      educationList: [], // 文化水平列表
      UserTypeList: [], // 人员类型列表

      // --------------  导入  -----------------------------------
      centerDialogVisible: false, // 导入学员弹窗显示状态
      templatelist: "", // 模板数据
      fileKey: "", //
      fileName: "",
      totalNum: 0,
      correctNum: 0,
      errorNum: 0,
      // --------------  查看学员信息  ----------------------------
      dialogViewStudentInfo: false, // 查看学员信息弹窗显示状态
      Form: {}, // 学员信息表单数据

      // --------------  新增  -----------------------------------
      dialogVisible: false, // 新增学员弹窗显示状态
      // 新增学员表单数据
      formData: {
        userName: "", // 姓名
        idCard: "", // 身份证号
        sex: "", // 性别
        mobile: "", // 联系电话
        idPhotoKey: "", // 证件照片key
        idPhoto: "", // 证件照片
        email: "", // 电子邮件
        workUnit: "", // 工作单位
        educationLevel: "", // 文化水平
        Coursestudy: [], // 学习课程
      },
      rules: {
        userName: [
          { required: true, message: "请输入学员姓名", trigger: "blur" },
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        Coursestudy: [
          { required: true, message: "请至少选择一项课程", trigger: "change" },
        ],
      },
      showtip: false,
      opendialog: true,
      dialogVisibleCropper: false,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 413, // 默认生成截图框宽度
        autoCropHeight: "100%", // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1.4], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: "contain",
      },
      hasIdPhotoSelect: [
        {
          value: true,
          label: "有",
        },
        {
          value: false,
          label: "无",
        },
      ],
      tableHaed: [],
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 10,
        total: null, // 共多少页
      },
      showKey: [],
    };
  },
  created() {
    this.getListData(this.fenYe.currentPage, this.fenYe.size);
    this.getUserTypeList(); // 获取人员类型
    this.getEducationList(); // 获取文化水平列表
    // this.getCompanyList(); // 获取公司列表
    this.getAuthenticationList(); // 获取认证状态列表
    this.getstydyStatusList(); // 获取学习状态列表
    this.getTableHeight()
    this.projectSource = sessionStorage.getItem("projectSource");
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    // 关闭查看学员信息弹窗
    doCloseViewStudentInfo() {
      this.dialogViewStudentInfo = false;
       this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 查看学员信息
    handleViewStudentInfo(row) {
      this.dialogViewStudentInfo = true;
      this.userId = row.userId
      this.projectId = row.projectId
      this.Form = {};
      this.$post("/biz/projectUser/v2/info", {
        projectId: row.projectId,
        userId: row.userId,
      })
        .then((ret) => {
          let records = ret.data;
          this.showKey = records.showKey;
          this.showValue = records.showValue;
          this.adaptable = records.adaptable;
          this.$set(this, "Form", this.showValue);
          this.getCompany(this.showValue.compId);
          this.$set(this.Form, "compId", this.showValue.compId);
          this.$set(this.Form, "idPhotoKey", this.showValue.idPhotoKey || "");
        })
        .catch((err) => {
          return;
        });
    },

    // 下载模板
    downloadExcel(templatePath) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = templatePath;
      let templateNames = templatePath.slice(8);
      link.setAttribute("download", templateNames);
      document.body.appendChild(link);
      link.click();
    },

    // 开始导入
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否将此表中学员绑定此班级?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.doExportInDisabled = true;
            if (this.fileKey) {
              const parmar = {
                projectId: this.projectId,
                excelUrl: this.fileKey,
              };
              this.$post("/biz/project/user/v2/import", parmar, 5000)
                .then((res) => {
                  if (res.status == 0) {
                    this.doProgress(res.data, this.projectId);
                  }
                })
                .catch((err) => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.fileKey = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    //获取导入进度
    doProgress(batchId, projectId) {
      this.$post(
        "/biz/project/user/v2/import/progress",
        { batchId, projectId },
        5000
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId, projectId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/project/user/v2/error/export",
          { batchId: this.batchId, projectId: this.projectId },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    // 点击批量导入
    handleExport() {
      this.centerDialogVisible = true;
      // 获取模板
      this.$post("/biz/project/user/v2/template", { projectId: this.projectId })
        .then((res) => {
          this.templatelist = res.data || [];
        })
        .catch((err) => {
          return;
        });
    },

    // 关闭导入学员弹窗
    doCloseload() {
      this.centerDialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.DialogForm = {};
      this.totalNum = 0;
      this.errorNum = 0;
      this.correctNum = 0;
      this.$refs["DialogForm"].resetFields();
       this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "dict") {
        return this.$setDictionary(el.dictionaries, row[el.key]);
      } else if (el.renderType == "boolean") {
        if (row[el.key] == 'true') {
          return "有";
        } else {
          return "无";
        }
      } else {
        return row[el.key] || "--";
      }
    },
    // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 获取列表数据
    getListData(page = 1, pagesize = this.fenYe.size) {
      const params = {
        pageNum: page,
        pageSize: pagesize,
        projectId: this.projectId,
        hasIdPhoto: this.dataForm.hasIdPhoto,
      };

      // 认证状态
      if (this.dataForm.authentication) {
        params.authentication = this.dataForm.authentication;
      }
      // 学习状态
      if (this.dataForm.completeState) {
        params.completeState = this.dataForm.completeState;
      }
      // 身份证号
      if (this.dataForm.idcard) {
        params.idcard = this.dataForm.idcard;
      }
      // 学生姓名
      if (this.dataForm.userName) {
        params.userName = this.dataForm.userName;
      }

      // 学生姓名
      if (this.dataForm.mobile) {
        params.mobile = this.dataForm.mobile;
      }
      // 入班时间
      if (this.dataForm.theClassTime) {
        params.startDate = this.dataForm.theClassTime[0];
        params.endDate = this.dataForm.theClassTime[1];
      }
      this.$post("/biz/projectUser/v2/listPage", params).then((ret) => {
        console.log(ret)
        this.tableData = ret.data.list;
        this.tableHaed = ret.data.showField;
        this.fenYe.total = ret.data.total;
        console.log(this.fenYe);
      });
      //   this.doFetch({
      //     // url: "/biz/projectUser/v2/listPage",
      //     url: "/biz/projectUser/v2/listPage",
      //     params,
      //     pageNum,
      //   });
    },
    // 序号
    indexMethod1(index) {
      return (this.fenYe.currentPage - 1) * this.fenYe.size + index + 1;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 120;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },

    // 获取人员类型
    getUserTypeList() {
      this.$post("/sys/dictionary/key", {
        dictKey: "USERTYPE",
      })
        .then((res) => {
          if (res.status == "0") {
            this.UserTypeList = res.data || [];
          }
        })
        .catch(() => {
          return;
        });
    },

    // 查询公司
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },

    // 获取认证状态列表
    getAuthenticationList() {
      const authentication =
        this.$setDictionary("AUTHENTICATION", "list") || [];
      let list = [];
      for (const key in authentication) {
        list.push({
          value: key,
          label: authentication[key],
        });
      }
      this.authenticationType = list;
    },

    // 获取学习状态列表
    getstydyStatusList() {
      const studyStatusList = this.$setDictionary("STUDY_STATUS", "list") || [];
      let list = [];
      for (const key in studyStatusList) {
        list.push({
          value: key,
          label: studyStatusList[key],
        });
      }
      this.studyStatusList = list;
    },
    // 获取文化水平列表
    getEducationList() {
      const educationList = this.$setDictionary("EDUCATION", "list") || [];
      let list = [];
      for (const key in educationList) {
        list.push({
          value: key,
          label: educationList[key],
        });
      }
      this.educationList = list;
    },

    // 跳转学习记录
    handleLearningRecord(row) {
      sessionStorage.setItem("row", JSON.stringify(row));
      this.$router.push({
        path: "/web/Learningrecords",
      });
    },
    //删除
    // 从本班移除
    handleDel(row) {
       this.$confirm("你确定要将该学员从本班移除吗?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
             this.$post('/biz/projectUser/removeCourseUser',{ userId: row.userId, projectId: this.projectId}).then(ret => {
        this.$message({
          message:'删除成功',
          type:'success'
        })
         this.getListData(this.fenYe.currentPage, this.fenYe.size);
      })
          })
          .catch(() => {
            return;
          });
     
      // this.doDel({
      //   url: "/biz/projectUser/removeCourseUser",
      //   msg: "你确定要将该学员从本班移除吗？",
      //   ps: {
      //     type: "post",
      //     data: { userId: row.userId, projectId: this.projectId },
      //   },
      // });
      
    },

    // 显示新增学员弹窗
    handleCreate() {
      if (this.remainCount > 0) {
        // if (false) {
        this.dialogVisible = true;
      } else {
        this.$alert("暂无剩余购买次数", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
        });
      }
    },
    // 关闭新增学员弹窗
    doClose() {
      this.dialogVisible = false;
    },

    // // 确定新增学员信息
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },

    doeAjax() {
      const parmar = {
        projectId:this.projectId,
        userId:this.userId,
        ...this.Form
      };
      console.log(parmar);
      this.$post("/biz/projectUser/v2/update", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.dialogViewStudentInfo = false;
            // this.doClose();
            this.getListData(this.fenYe.currentPage, this.fenYe.size);
            this.reset();
          }
        })
        .catch((err) => {
          return;
        });
    },

    reset() {
      this.Form = {
      };
    },

    delIdPhoto() {
      this.Form.idPhoto = "";
      this.Form.idPhotoKey = "";
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.companyList = [res.data];
      });
    },

    /**
     * 证件照
     */
    urltoBase64(res) {
      const that = this;
      const reader = new FileReader();
      reader.readAsDataURL(res.raw);
      reader.onload = function(e) {
        that.option.img = e.target.result;
      };
    },
    // 上传证件照
    handleAvatarSuccessIdPhoto(res) {
      this.urltoBase64(res);
      if (this.opendialog) {
        this.dialogVisibleCropper = true;
      }
    },
    beforeIdPhotoUpload(file) {
      this.opendialog = true;
    },

    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        let myFile = new window.File([data], this.formData.userName + ".jpeg");
        this.cropperFile = myFile;
        this.upload({
          name: this.Form.userName + ".jpeg",
          raw: myFile,
        });
        this.dialogVisibleCropper = false;
      });
    },
    upload(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.idPhotoShow = result.data.fileURL;
          this.Form.idPhotoKey = result.data.fileKey;
          // this.urltoBase64(res);
          // if (this.opendialog) {
          //   this.dialogVisibleCropper = true;
          // }
        })
        .catch((err) => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    docropperCencal() {
      this.opendialog = true;
      this.dialogVisibleCropper = false;
    },
  },
};
</script>
<style lang="less">
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
.pic_upload {
  .el-upload {
    border: 0;
    display: block;
    max-height: 50px !important;
    text-align: left;
  }
}
.cropper_tip {
  background-color: #f1f7fd;
  padding: 15px 20px;
  h3 {
    margin-bottom: 15px;
  }
  .tip_index {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #5b6be7;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
  }
  .tip_text {
    min-width: 140px;
  }
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
// 图片裁剪
.vueCropper_dialog {
  .el-upload {
    display: inline-block;
    border: 0;
    height: 16px !important;
  }
}
// 截图
.cropper-content {
  .cropper {
    width: auto;
    height: 578px;
  }
}
</style>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
</style>
