import { render, staticRenderFns } from "./studentList.vue?vue&type=template&id=fc5b52f0&scoped=true"
import script from "./studentList.vue?vue&type=script&lang=js"
export * from "./studentList.vue?vue&type=script&lang=js"
import style0 from "./studentList.vue?vue&type=style&index=0&id=fc5b52f0&prod&lang=less"
import style1 from "./studentList.vue?vue&type=style&index=1&id=fc5b52f0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc5b52f0",
  null
  
)

export default component.exports